import moment from 'moment';
import english from '../../../intl/en';
import spanish from '../../../intl/es';
import deutsch from '../../../intl/de';
import french from '../../../intl/fr';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import { Languages, SetLanguageAction } from './types';
import { instanceConfigs } from '../../../utils';

const { REACT_APP_INSTANCE } = process.env;

const config =
  instanceConfigs[REACT_APP_INSTANCE as string] || instanceConfigs.default;

const language =
  Languages[(navigator.language as string).toUpperCase()] || config.language;

const setIntl = (lang = Languages.FR) => {
  switch (lang) {
    case Languages.ES:
      moment.locale('es');
      return spanish;
    case Languages.EN:
      moment.locale('en');
      return english;
    case Languages.DE:
      moment.locale('de');
      return deutsch;
    case Languages.FR:
      moment.locale('fr');
      return french;
    default:
      moment.locale('fr');
      return french;
  }
};

export interface ILocaleState {
  lang: Languages;
  messages: any;
}

export const initialState: ILocaleState = {
  lang: language,
  messages: setIntl(language),
};

export const locale = (state = initialState, action: SetLanguageAction) => {
  switch (action.type) {
    case 'LOCALE/SPANISH':
      return {
        lang: Languages.ES,
        messages: spanish,
      };
    case 'LOCALE/ENGLISH':
      return {
        lang: Languages.EN,
        messages: english,
      };
    case 'LOCALE/DEUTSCH':
      return {
        lang: Languages.DE,
        messages: deutsch,
      };
    case 'LOCALE/FRENCH':
      return {
        lang: Languages.FR,
        messages: french,
      };

    default:
      return state;
  }
};
