import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl';
import { sizes } from '../../theme';
import {
  AppState,
  unpublishCreativityByWarning,
  UnpublishCreativityByWarningActionCreator,
  ICreativity,
  selectTitle,
  selectCustomTitle,
  getPublishedEmergency,
  isEmergencyRoute,
} from '../../redux/modules';

import LinkStyle from '../Link';
import Text from '../Text';
import Weatherwarning from '../Weatherwarning';

import {
  HeaderTitle,
  ListItem,
  Subheader,
  Listheader,
  Img,
  ListDivider,
  Container,
  Wrapper,
} from './styles';
import { ConfigConsumer, UserConsumer } from '../../providers';

interface IProps {
  dashboard?: string;
  header?: string;
  publishedEmergency?: ICreativity;
  unpublishCreativityByWarning?: UnpublishCreativityByWarningActionCreator;
  customTitle?: string;
  title?: string;
  name?: string;
  intl?: InjectedIntl;
  isEmergency?: boolean;
  auth?: any;
  history?: any;
}

class Header extends Component<IProps> {
  render() {
    const {
      unpublishCreativityByWarning,
      publishedEmergency,
      intl,
      isEmergency,
      customTitle,
      title,
    } = this.props;

    return (
      <UserConsumer>
        {user => (
          <ConfigConsumer>
            {config => (
              <>
                <Container>
                  <Wrapper>
                    <Link to={'/'}>
                      <Img src={config.logo} />
                    </Link>
                    {publishedEmergency ? (
                      <Weatherwarning
                        unpublishCreativityByWarning={
                          unpublishCreativityByWarning!
                        }
                        emergency={publishedEmergency}
                      />
                    ) : null}
                  </Wrapper>
                  <Listheader>
                    <ListItem>
                      <LinkStyle
                        type={'header'}
                        to={'/'}
                        exact
                        text={intl!.formatMessage({
                          id: 'route.dashboard',
                        })}
                      />
                    </ListItem>
                    <ListItem>
                      <LinkStyle
                        type={'header'}
                        to={'/creative'}
                        text={intl!.formatMessage({
                          id: 'route.creative',
                        })}
                        className={
                          window.location.href.includes('editor') ||
                          window.location.href.includes('creative')
                            ? 'active'
                            : ''
                        }
                      />
                    </ListItem>
                    <ListItem>
                      <LinkStyle
                        type={'header'}
                        to={'/emergency'}
                        text={intl!.formatMessage({
                          id: 'route.emergency',
                        })}
                        disabled={!user.isAdmin}
                      />
                    </ListItem>
                    <ListDivider type="vertical" />{' '}
                    <ListItem>
                      {!user.loggingOut ? (
                        <Text
                          link
                          bold
                          onClick={user.logout}
                          fontSize={sizes.M}
                        >
                          <FormattedMessage id="app.logout" />
                        </Text>
                      ) : (
                        <Text link bold fontSize={sizes.M}>
                          <FormattedMessage id="app.logout" />
                        </Text>
                      )}
                    </ListItem>
                  </Listheader>
                </Container>
                <Subheader>
                  <HeaderTitle emergency={isEmergency}>
                    {customTitle ? (
                      customTitle
                    ) : (
                      <FormattedMessage id={title!} />
                    )}
                  </HeaderTitle>
                </Subheader>
              </>
            )}
          </ConfigConsumer>
        )}
      </UserConsumer>
    );
  }
}

export default connect(
  (state: AppState) => ({
    title: selectTitle(state),
    isEmergency: isEmergencyRoute(state),
    customTitle: selectCustomTitle(state),
    publishedEmergency: getPublishedEmergency(state),
  }),
  { unpublishCreativityByWarning }
)(injectIntl<any>(Header));
