import React, { createRef } from 'react';
import moment, { Moment } from 'moment';
import { injectIntl, InjectedIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Picker, PickerComponent } from '../../../../components';
import WeekDaySelector from '../WeekDaySelector';
import HourSelector from '../HourSelector';

import {
  IVisibility,
  updateVisibilityDateOrTime,
  AppState,
  Status,
} from '../../../../redux/modules';

import {
  ScheduleContainer,
  DatePicker,
  DatePickerLabel,
  WeekdayPicker,
  HourPicker,
} from '../styles';

interface IProps {
  creativityId: string;
  intl?: InjectedIntl;
  updateVisibilityDateOrTime?: any;
  visibility?: IVisibility;
  status: Status;
  endDate?: string;
  startDate?: string;
}

class ScheduleSelector extends React.Component<IProps> {
  private dateFrom: React.RefObject<PickerComponent> = createRef();

  private dateTo: React.RefObject<PickerComponent> = createRef();

  changeVisibility = (group: string, type: string) => (date: Moment) => {
    if (group === 'time') {
      const hour = date ? date.hour() : undefined;
      const minutes = date ? date.minute() : undefined;
      const value =
        hour !== undefined && minutes !== undefined
          ? `${hour}:${minutes < 10 ? `0${minutes}` : minutes}`
          : undefined;
      this.props.updateVisibilityDateOrTime(
        this.props.creativityId,
        group,
        type,
        value
      );
    }

    if (group === 'date') {
      const value = date ? date.toDate().toLocaleDateString() : undefined;
      this.props.updateVisibilityDateOrTime(
        this.props.creativityId,
        group,
        type,
        value
      );
    }
  };

  getDateVisibility(type: string) {
    const { endDate, startDate } = this.props;
    let date = type === 'startDate' ? startDate : endDate;
    return date ? moment(date, 'DD/MM/YYYY') : undefined;
  }

  getTimeVisibility(group: string, type: string, defaultTime: string) {
    const { visibility } = this.props;
    let time =
      visibility &&
      visibility.rules &&
      visibility.rules.find(
        x =>
          x.type === group &&
          x.comparator === (type === 'timeFrom' ? 'gte' : 'lte')
      );
    return time ? time.value : defaultTime;
  }

  disabledDateFrom = (current: Moment) => {
    // Can not select days before today
    const { endDate } = this.props;
    return (
      (endDate &&
        current >
          moment(endDate, 'DD-MM-YYYY')
            .add(-1, 'days')
            .startOf('day')) ||
      current < moment().subtract(1, 'day')
    );
  };

  disabledDateTo = (current: Moment) => {
    // Can select today
    const { startDate } = this.props;
    return (
      !startDate ||
      (startDate &&
        current &&
        current < moment(startDate, 'DD-MM-YYYY').startOf('day'))
    );
  };

  render() {
    const { creativityId, status } = this.props;
    return (
      <ScheduleContainer>
        <DatePicker>
          <DatePickerLabel>
            <FormattedMessage id="editor.visibility.dateRange" />
          </DatePickerLabel>
          <Picker
            {...{
              id: `${creativityId}_dateFrom`,
              ref: this.dateFrom,
              disabled:
                status === Status.PUBLISHED ||
                status === Status.PENDING_VALIDATION
                  ? true
                  : false,
              disabledDate: this.disabledDateFrom,
              value: this.getDateVisibility('startDate'),
              onPress: this.changeVisibility('date', 'gte'),
              type: 'date',
            }}

            // id={`${creativityId}_dateFrom`}
            // ref={this.dateFrom}
            // disabled={
            //   status === Status.PUBLISHED ||
            //   status === Status.PENDING_VALIDATION
            // }
            // disabledDate={this.disabledDateFrom}
            // value={this.getDateVisibility('startDate')}
            // onPress={this.changeVisibility('date', 'gte')}
            // type={'date'}
          />
          <Picker
            {...{
              id: `${creativityId}_dateTo`,
              ref: this.dateTo,
              disabled:
                status === Status.PUBLISHED ||
                status === Status.PENDING_VALIDATION
                  ? true
                  : false,
              disabledDate: this.disabledDateTo,
              value: this.getDateVisibility('endDate'),
              onPress: this.changeVisibility('date', 'lte'),
              type: 'date',
            }}

            // id={`${creativityId}_dateTo`}
            // ref={this.dateTo}
            // disabled={
            //   status == Status.PUBLISHED || status === Status.PENDING_VALIDATION
            // }
            // disabledDate={this.disabledDateTo}
            // value={this.getDateVisibility('endDate')}
            // onPress={this.changeVisibility('date', 'lte')}
            // type={'date'}
          />
        </DatePicker>
        <WeekdayPicker>
          <WeekDaySelector creativityId={creativityId} status={status} />
        </WeekdayPicker>
        <HourPicker>
          <HourSelector
            onChange={this.changeVisibility}
            fromTime={this.getTimeVisibility('time', 'timeFrom', '0:00')}
            toTime={this.getTimeVisibility('time', 'timeTo', '23:59')}
            onSelectAll={this.props.updateVisibilityDateOrTime}
            status={status}
          />
        </HourPicker>
      </ScheduleContainer>
    );
  }
}

export default connect(
  (state: AppState, props: IProps) => ({}),
  { updateVisibilityDateOrTime }
)(injectIntl<any>(ScheduleSelector));
