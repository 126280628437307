import styled from 'styled-components';
import { Checkbox, Col } from 'antd';
import { colors } from '../../../theme';
import { Picker, Input, Button, Link, StatusTag } from '../../../components';
import { Radio } from 'antd';

export const Container = styled.div`
  flex: 1;
`;

export const Section = styled.div`
  // margin-bottom: 20px;
`;

export const ElementsSection = styled.div`
  margin: 24px;
`;

export const SectionLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 15px;
`;

export const RightSection = styled.div`
  float: right;
  font-size: 1em;
  margin-left: 35px;
  button {
    font-size: 12px;
    padding: 0 10px;
  }
`;

export const RightSectionLink = styled(RightSection)`
  color: ${colors.lightBlue};
`;

export const H1Custom = styled.h1`
  font-size: 1.3em;
  float: left;
  margin-right: 10px;
  color: #7c8c8f;
`;

export const H4Custom = styled.h4`
  font-size: 0.9em;
  color: grey;
`;

export const CheckboxCustom = styled(Checkbox)`
  font-family: 'Montserrat';
  margin: 10px;
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${props => props.theme.colors.blue};
  }
`;

export const PickerComponent = styled(Picker)`
  margin: 100px;
`;

export const CustomButton = styled(Button)`
  min-height: 32px;
  padding: 0 10px;
  font-weight: ${props => props.theme.fontWeights.regular};
  margin-bottom: 8px;
  font-size: ${props => props.theme.sizes.M};
  line-height: 22px;
  background-color: ${props => props.theme.colors.blue};
  &:disabled {
    color: ${props => props.theme.colors.disabledGrey};
    background-color: rgba(0, 0, 0, 0.04);
    border: 1px solid ${props => props.theme.colors.moreSuperDarkerGrey};
  }
  &:hover,
  &:focus,
  &:active {
    background-color: ${props => props.theme.colors.vividBlueLight};
    color: ${props => props.theme.colors.white};
  }
`;

export const CustomLinkMargin = styled(Link)`
  margin-left: 10px;
`;

export const CustomContainerButton = styled.div``;

export const SectionPicker = styled.div`
  width: 100%;
`;

export const SectionControls = styled.div``;

export const ColFix = styled(<any>Col)`
  margin-top: 17px;
`;

//a partir de aquí, estilos nuevos

export const EditorWrapper = styled.div`
  height: auto;
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.lightGrey};
`;

export const NameSection = styled.div`
  display: flex;
  align-items: center;
  margin: 9px 24px;
`;

export const SectionTitle = styled.div`
  font-size: ${props => props.theme.sizes.M};
  color: ${props => props.theme.colors.megaDarkGrey};
  font-weight: ${props => props.theme.fontWeights.bold};
  line-height: 22px;
  margin-right: 20px;
`;

export const SectionContent = styled.div`
  width: 100%;
`;

export const CustomNameInput = styled(Input)`
  margin-top: 15px;
  width: 100%;
  font-size: ${props => props.theme.sizes.M};
  line-height: 22px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.colors.lightGrey};
`;

export const ScheduleSection = styled.div`
  margin: 24px;
`;

//Estilos de ScheduleSelector

export const ScheduleContainer = styled.div``;

export const DatePicker = styled.div`
  width: 100%;
  margin-top: 15px;
  display: flex;
  align-items: center;
`;

export const DatePickerLabel = styled.span`
  font-size: ${props => props.theme.sizes.M};
  line-height: 22px;
`;

export const HourPicker = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
`;

export const FlexHourPickers = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Text = styled.span`
  margin-left: 10px;
  margin-right: 10px;
`;

//estilos de WeekdaySelector

export const WeekdayPicker = styled.div`
  margin-top: 24px;
`;

export const RadioButtonsContainer = styled(Radio.Group)`
  display: flex;
  flex-direction: column;
`;

export const WeekDayDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.colors.lightGrey};
  margin: 15px 10px 0 0;
`;

//Estilos de LoCationSection

export const LocationSection = styled.div`
  margin: 24px;
`;

export const SectionSubtitle = styled.div`
  font-size: ${props => props.theme.sizes.M};
  line-height: 22px;
  color: grey;
`;

export const CustomImageVideoElementInput = styled(Input)`
  &.ant-input {
    width: 100%;
  }
`;

export const ImageVideoElementContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CustomLabelImageVideoElement = styled.label`
  color: ${props => props.theme.colors.superDarkGrey};
  font-size: ${props => props.theme.sizes.M};
  font-weight: ${props => props.theme.fontWeights.medium};
  line-height: 22px;
  margin-bottom: 15px;
`;

export const FlexImageVideoElementContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  width: 75%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const HeaderRightSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TitleCreative = styled.p`
  color: ${props => props.theme.colors.megaDarkGrey};
  font-size: ${props => props.theme.sizes.XL};
  font-weight: ${props => props.theme.fontWeights.medium};
  margin-bottom: 0;
`;
