import moment from 'moment';
import { StatusFilter, Filter } from '../filters/types';
import { IFilterState } from '../filters/reducer';
import { ICreativity, Status } from '../creativities/types';

export const statusFilterList: {
  key: StatusFilter;
  label: string;
  value: StatusFilter;
}[] = [
  {
    key: StatusFilter.ALL,
    label: 'All',
    value: StatusFilter.ALL,
  },
  {
    key: StatusFilter.DRAFT,
    label: 'Draft',
    value: StatusFilter.DRAFT,
  },
  {
    key: StatusFilter.PUBLISHED,
    label: 'Published',
    value: StatusFilter.PUBLISHED,
  },
  {
    key: StatusFilter.COMPLETED,
    label: 'Completed',
    value: StatusFilter.COMPLETED,
  },
];

export const groupFilterList = [];

export const filtersSelector = (type: Filter) => (state: {
  filters: IFilterState;
}) => {
  const filter = state.filters.filters[type];

  if (!filter) {
    return filterOps.default;
  }

  return filterOps[type][filter]
    ? filterOps[type][filter]()
    : filterOps[type].selection(filter);
};

const filterOps: {
  [key: string]: any;
} = {
  default: () => true,
  [Filter.GROUP]: {
    All: () => () => true,
    selection: (value: string) => (creativity: ICreativity) =>
      (creativity && creativity.groups && creativity.groups.includes(value)) ||
      creativity.allPlayers,
  },
  [Filter.STATUS]: {
    [StatusFilter.ALL]: () => () => true,
    [StatusFilter.PUBLISHED]: () => (creativity: ICreativity) =>
      creativity.status ===
      Status.PUBLISHED /*&&
      creativity.visibility &&
      creativity.visibility.rules &&
      checkStatusRules[StatusFilter.PUBLISHED](creativity.visibility.rules)*/,
    [StatusFilter.DRAFT]: () => (creativity: ICreativity) =>
      creativity.status === Status.DRAFT,
    [StatusFilter.PENDING]: () => (creativity: ICreativity) =>
      creativity.status === Status.PENDING_VALIDATION,
    [StatusFilter.COMPLETED]: () => (creativity: ICreativity) =>
      creativity.status ===
      Status.COMPLETED /*&&
      creativity.visibility &&
      creativity.visibility.rules &&
      checkStatusRules[StatusFilter.COMPLETED](creativity.visibility.rules)*/,
  },
};

const checkStatusRules: {
  [key: string]: (rules: any[]) => boolean;
} = {
  [StatusFilter.COMPLETED]: (rules: any[]) => {
    const rulesLt = rules.find(x => x.comparator === 'lte');
    if (rulesLt) {
      return moment(rulesLt.value, 'DD/MM/YYYY').isBefore();
    }
    return false;
  },
  [StatusFilter.PUBLISHED]: (rules: any[]) => {
    const rulesLt = rules.find(x => x.comparator === 'lte');
    if (rulesLt) {
      return moment(rulesLt.value, 'DD/MM/YYYY').isSameOrAfter();
    }
    return false;
  },
};
